import Locations from '../../services/api/Locations';
import Vue from "vue";


const state = {
  locations: [],
  custom_fields: {},
  local_working_time: {},
  global_working_time: {},
  detectDevices: [],
  location_form: [],
  locations_list: [],
  total_locations: null,
  links: null,
  pages_number: null,
  filterLocations: {
    ordering: "-online_status",
    search: null,
    location_type_uuid: null,
    country_uuid: null,
    tag_uuid: null,
    page: 1,
    page_size: 36
  },
  query: 'ordering=-online_status&page_size=36&page=1',
  location_devices: [],
  country_space: [],
  selectedDeviceLocation: '',
  location_type_by_space: [],
  locationTypes: [],
  countries: [],
  flyouts: {
    locationInfo: false,
    editLocation: false,
    addLocation: false,
  },
  detectedCameras: {},
  selectedLocation: {},
  detectingCameras: false,
  sortAscending: [
    {
      text: 'Online/Offline',
      value: '-online_status'
    },
    {
      text:'Time Added',
      value: '-created_at'
    },    
    {
      text:'Time Modified',
      value:'-updated_at'
    },

  ],
  sortDescending: 
    [
      {
        text: 'Online/Offline',
        value: 'online_status'
      },
      {
        text:'Time Added',
        value: 'created_at'
      },    
      {
        text:'Time Modified',
        value:'updated_at'
      }, 
    ] 
};
const getters = {
  currentPage: state => state.filterLocations.page,

  globalWorkingTime: state => state.global_working_time,

  localWorkingTime: state => state.local_working_time,

  getOrdering: state => state.filterLocations.ordering,

  getLocationList: state => state.locations_list,

  getTotalLocations: state => state.total_locations,
  
  getCustomFields: state => state.custom_fields,

  getLinksLocations(state){
    return state.links;
  },
  //getter for detecting devices
  getDetectedDevices(state){
    return state.detectDevices;
  },
  getOnlineDevicesLocations(state){
    return state.location_devices.filter(onlineDevices => onlineDevices.online === true);
  },
  getOfflineDevicesLocations(state){
    return state.location_devices.filter(onlineDevices => onlineDevices.online === false);
  },
  getFilterName(state){
    return state.filterLocations.name;
  },
  getTotalPages(state){
    return state.pages_number;
  },
  getFilterLocationType(state){
    return state.filterLocations.location_type_uuid;
  },
  getFilterCountry(state){
    return state.filterLocations.country_uuid;
  },
  getFilterTag(state){
    return state.filterLocations.tag_uuid;
  },
  getPageNumber(state){
    state.filterLocations.page;
  },
  getLocationCount(state){
    state.filterLocations.page_number
  },
  getQuery(state){
    return state.query;
  },
  getFilterLocations(state){
    return state.filterLocations;
  },
  getCountryBySpace(state){
    return state.country_space;
  },
  getSortAscending(state){
    return state.sortAscending;
  },
  getSortDescending(state){
    return state.sortDescending;
  },
  getLocations(state) {
    return state.locations;
  },
  getSelectedDevice(state) {
    return state.location_devices.find(c => c.uuid === state.selectedDeviceLocation);
    //trazi po stringu odnopsno uuid
  },
  getLocationTypes(state) {
    return state.locationTypes;
  },
  getCountries(state) {
    return state.countries;
  },
  getSelectedLocation(state) {
    return state.selectedLocation;
  },
  getFlyoutEditLocation(state) {
    return state.flyouts.editLocation;
  },
  getDetectedCameras(state) {
    return state.detectedCameras;
  },
  getCameraDetectionState(state) {
    return state.detectingCameras;
  },
  getDevicesByLocation(state) {
    return state.location_devices;
  }
};

const actions = {
  updateDeviceLocations({commit, dispatch}, data) {
      return new Promise((resolve) => {
        const {device, index} = actions.getDeviceFromUUID(data.uuid)
        commit('UPDATE_DEVICE', {
          index: index,
          fields: data.fields
        });
        if (data.uuid === state.selectedDevice.uuid) {
          dispatch('getSpecificDevice', {
            route: state.selectedDeviceType,
            uuid: data.uuid
          })
        }
        
        resolve(device);
      })
    },
  getDeviceFromUUID(uuid) {
      const device = state.location_devices.find((device) => device.uuid === uuid)
      const index = state.location_devices.findIndex((device) => device.uuid === uuid)
      return {device, index}

    },
  getLocationForm({commit}, uuid){
    return new Promise((resolve) => {
      Locations.get(uuid).then(response => {
        commit('SET_LOCATION_FORM', response.data.locations);
        
        resolve(response.data.locations);
      }).catch(err => {
        
        console.log(err);
      });
    });
  },
  getLocations({commit, rootState}, uuid) {
    return new Promise((resolve) => {
      Locations.get(uuid, state.query).then(response => {
        console.log("location called");
        console.log(response)
        let locations = response.data.locations;
        let links = response.data.links;
        let total_locations = response.data.objects_count;
        let view = rootState.view;
        let total_pages = response.data.pages_count;
        commit('SET_TOTAL_LOCATIONS', total_locations);
        commit('SET_TOTAL_PAGES', total_pages);
        if (state.filterLocations.page > 1 && view === 'grid') {
          locations = state.locations.concat(locations);
        }
        // Filter out unique devices based on device.uuid
        const uniqueDevices = Array.from(new Set(locations.map(location => location.uuid))).map(uuid => locations.find(location => location.uuid === uuid));
        commit('SET_LOCATIONS', uniqueDevices);
        commit('SET_LINKS_LOCATIONS', links);
        resolve();
      }).catch(err => {
        console.log(err);
      });
    });
  },
  getCountryBySpace({commit}, uuid) {
    return new Promise((resolve) => {
      Locations.getCountryBySpace(uuid).then(response => {
        commit('SET_COUNTRY_SPACE', response.data);
        resolve(response.data);
      }).catch(err => {
        
        console.log(err);
      });
    });
  },
  getLocationForLists({commit}){
    return new Promise(() =>{
      Locations.getList().then((response)=>{
        console.log(response.data)
        commit('SET_LOCATIONS_LIST', response.data.locations);
      })
    })
  },
  getLocationTypesBySpace({commit}) {
    return new Promise((resolve) => {
      Locations.getLocationTypes().then(response => {
        commit('SET_LOCATION_TYPES_SPACE', response.data);
        resolve(response.data);
      }).catch(err => {
        console.log(err);
      });
    });
  },
  getLocationDevices({commit}, params) {
    return new Promise((resolve) => {
      Locations.getDeviceByLocation(params.space_uuid, params.location_uuid).then(response => {
        
        commit('SET_LOCATION_DEVICES', response.data);
        resolve(response.data);
      }).catch(err => {
        console.log(err);
      });
    });
  },
  getLocationTypes({commit}) {
    return new Promise((resolve) => {
      Locations.getTypes().then(response => {
        commit('SET_LOCATION_TYPES', response.data);
        resolve(response.data);
      }).catch(err => {
        console.log(err);
      });
    });
  },
  getCountries({commit}) {
    return new Promise((resolve) => {
      Locations.getCountries().then(response => {
        commit('SET_COUNTRIES', response.data);
        resolve(response.data);
      }).catch(err => {
        console.log(err)
      });
    });
  },
  getLocationByUUID({commit}, uuid) {
    return new Promise((resolve) => {
      commit('SET_SELECTED_LOCATION', {});
      Locations.getLocation(uuid).then(response => {
        commit('SET_SELECTED_LOCATION', response.data);
        resolve();
      });
    });
  },
  setLocationFlyout({commit}, data) {
    return commit('SET_FLYOUT', data);
  },
  detectDevices({commit}, uuid) {
    return new Promise((resolve) => {
      Locations.detectDevices(uuid).then(() => {
        commit("DETECTING_CAMERAS", true);
        resolve();
      })
    })
  },
  setDetectDevices({commit}, uuid){
    return new Promise((resolve)=>{
      Locations.getDetectDevices(uuid).then((response)=>{
        commit("SET_DETECTED_DEVICES", response.data)
        resolve();
      })
    })
  },
  getCameras({commit}, uuid) {
    return new Promise((resolve) => {
      Locations.getCameraItems(uuid).then((response) => {
        commit("SET_LOCATION_CAMERAS", {
          uuid: uuid,
          cameras: response.data.cameras
        })
        commit("DETECTING_CAMERAS", false)
        resolve();
      })
    })
  },
  saveDetectedCameras({commit}, data) {
    commit("SET_LOCATION_CAMERAS", data)
    commit("DETECTING_CAMERAS", false)
  },
  deleteLocation({ commit }, data) {
    return new Promise((resolve, reject) => {
      Locations.deleteLocation(data.space_uuid, data.uuid)
        .then(() => {
          commit('DELETE_LOCATION', data.uuid);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getLocationFromUUID(uuid) {
    const location = state.locations.find((location) => location.uuid === uuid)
    const index = state.locations.findIndex((location) => location.uuid === uuid)
    console.log(location)
    return{ location, index }
  },
  updateLocation({commit}, data) {
    return new Promise((resolve) => {
      const {location, index} = actions.getLocationFromUUID(data.uuid)
      console.log(state.locations)
      commit('UPDATE_LOCATION_STATE', {
        index: index,
        fields: data.fields
      });
      resolve(location);
    })
  },
  setCheckLocationType({commit}, data){
    commit('SET_CHECK_LOCATION_TYPE', data);
  },
  setCheckCountry({commit}, data){
    commit('SET_CHECK_COUNTRY', data);
  },
  setCheckTags({commit}, data){
    commit('SET_CHECK_LOCATION_TAGS', data);
  },
  setLocationFilterName({commit}, data){
    commit('SET_LOCATION_FILTER_NAME', data);
  },
  setQuery({commit}, data){
    commit('SET_QUERY', data);
  },
  setOrdering({commit}, data){
    commit('SET_LOCATION_FILTER_ORDERING', data);
  },
  setGlobalWorkingTime({commit}){
    return new Promise((resolve) => {
      Locations.getWorkingTimeGlobal().then((response) => {
        console.log(response);
        commit("SET_GLOBAL_WORKING_TIME", response.data)
        resolve();
      })
    })
  },
  editWorkingTimeGlobal({commit}, data){
    return new Promise((resolve) => {
      Locations.editWorkingTimeGlobal(data).then((response) => {
        commit("SET_GLOBAL_WORKING_TIME", response.data)
        resolve();
      })
    })
  },
  setLocalWorkingTime({commit},data){
    return new Promise((resolve) => {
      Locations.getWorkingTimeLocal(data).then((response) => {
        console.log(response);
        commit("SET_LOCAL_WORKING_TIME", response.data)
        resolve();
      })
    })
  },
  editWorkingTimeLocal({commit},uuid, data){
    return new Promise((resolve) => {
      Locations.editWorkingTimeLocal(uuid, data).then((response) => {
        commit("SET_LOCAL_WORKING_TIME", response.data)
        resolve();
      })
    })
  },
  getSpaceCustomFields({ commit }) {
    try {
      Locations.getSpace().then(response => {
        const customFields = Object.keys(response.data)
          .filter(key => key.startsWith("custom_")) // Filter all custom fields
          .reduce((acc, key) => {
            // Extract the custom index (e.g., '1' from 'custom_1_alias') and the field name ('alias', 'enabled', etc.)
            const [, index, field] = key.match(/custom_(\d+)_(\w+)/);
  
            // Ensure the index exists in the accumulator
            if (!acc[index - 1]) {
              acc[index - 1] = { index: Number(index) }; // Initialize the object with the index
            }
  
            // Add the custom field to the correct index in the accumulator
            acc[index - 1][field] = response.data[key];
  
            return acc;
          }, []);
  
        // Commit the parsed customFields to the Vuex store
        commit('SET_SPACE', customFields);
      });
    } catch (err) {
      console.error('Error fetching custom fields:', err);
    }
  },
  updateCustomFields({ commit }, payload) {
    // Send payload to API or save locally
    // Then commit to mutation if needed
    commit('UPDATE_CUSTOM_FIELD', payload);
  },
  
};
const mutations = {
  SET_QUERY(state, data){
    state.query = data;
  },
  SET_LOCATIONS_LIST(state, data){
    state.locations_list = data;
  },
  SET_DETECTED_DEVICES(state, data){
    state.detectDevices = data;
  },
  SET_PAGE_NUMBER(state, data){
    state.filterLocations.page = data;
  },
  SET_LINKS_LOCATIONS(state, data){
    state.links = data;
  },
  SET_LOCATIONS_NUMBER(state, data){
    state.filterLocations.page_size = data;
  },
  SET_CHECK_LOCATION_TYPE(state, data){
    localStorage.setItem("checkLocationType", JSON.stringify(data));
    state.filterLocations.location_type_uuid = data;
  },
  SET_TOTAL_PAGES(state, payload){
    state.pages_number = payload;
  },
  SET_LOCATION_FORM(state, payload){
    state.location_form = payload;
  },
  SET_TOTAL_LOCATIONS(state, payload){
    state.total_locations = payload;
  },
  SET_CHECK_COUNTRY(state, data){
    localStorage.setItem("checkCountry", JSON.stringify(data));
    state.filterLocations.country_uuid = data;
  },
  SET_CHECK_LOCATION_TAGS(state, data){
    localStorage.setItem("checkLocationTags", JSON.stringify(data));
    state.filterLocations.tag_uuid = data;
  },
  SET_LOCATION_FILTER_NAME(state, data){
    state.filterLocations.search = data;
  },
  SET_LOCATION_FILTER_ORDERING(state, data){
    state.filterLocations.ordering = data;
  },
  SET_LOCATION_DEVICE_INFO(state,  data){
      state.location_device_info = data;
    },
  SET_COUNTRY_SPACE(state,  data){
      state.country_space = data;
    },
  SET_LOCATION_DEVICE_EDIT(state,  data){
      state.location_device_edit = data;
    },
  SET_FLYOUT(state, data) {
    state.flyouts = data;
  },
  SET_COUNTRIES(state, data) {
    state.countries = data;
  },
  SET_LOCATION_TYPES(state, data) {
    state.locationTypes = data;
  },
  SET_LOCATION_TYPES_SPACE(state, data) {
    state.locationTypesSpace = data;
  },
  SET_LOCATIONS(state, payload) {
    state.locations = payload;
  },
  SET_SELECTED_LOCATION(state, payload) {
    state.selectedLocation = payload;
    if (state.selectedLocation && state.selectedLocation.tags) {
      state.selectedLocation.tag_uuids = [];
      state.selectedLocation.tags.forEach(tag => {
        state.selectedLocation.tag_uuids.push(tag.uuid);
      });
    }
  },
  SET_LOCATION_CAMERAS(state, payload) {
    state.detectedCameras[payload.uuid] = payload.cameras
  },
  DETECTING_CAMERAS(state, payload) {
    state.detectingCameras = payload;
  },
  UPDATE_LOCATION(state, payload) {
    state.locations = [
      ...state.locations.filter((location) => location.uuid !== payload.uuid),
      payload,
    ];
  },
   UPDATE_DEVICE(state, payload) {
    console.log(payload)
    payload.fields.forEach((field) => {
      state.location_devices[payload.index][field.key] = field.value
      Vue.set(state.location_devices, payload.index, state.location_devices[payload.index]);//confirming data changes  
    });
  },
  SET_LOCATION_DEVICES(state, data) {
      state.location_devices = data.devices;
  },
  DELETE_LOCATION(state, uuid) {
    state.locations = state.locations.filter(location => location.uuid !== uuid);
  },
  PUSH_LOCATION(state, payload) {
    state.locations.push(payload);
  },
  UPDATE_LOCATION_STATE(state, payload) {
    payload.fields.forEach((field) => {
    state.locations[payload.index][field.key] = field.value
    Vue.set(state.locations, payload.index, state.locations[payload.index]);//confirming data changes
      
    });
  },
  SET_GLOBAL_WORKING_TIME(state, payload){
    state.global_working_time = payload;
  },
  SET_LOCAL_WORKING_TIME(state, payload){
    state.local_working_time = payload;
  },
  SET_SPACE(state, payload){
    state.custom_fields = payload;
  },
  UPDATE_CUSTOM_FIELD(state, updatedField) {
    // Find the field by index and update it
    const index = state.custom_fields.findIndex(field => field.index === updatedField.index);
    if (index !== -1) {
      // Update the custom field in the Vuex state
      Vue.set(state.custom_fields, index, updatedField);
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
