<template>
  <div v-if="currentDevice">
    <v-dialog width="600" v-model="appConfigModal" v-if="Object.keys(currentAppConfiguration).length > 0">
      <v-card>
        <v-card-title class="text-h5">{{ currentAppConfiguration.templateConfig.header }}</v-card-title>
        <v-card-subtitle class="pt-2">{{ currentAppConfiguration.templateConfig.description }}</v-card-subtitle>
        <v-card-text class="mt-4">
          <div v-for="(layer, index) in currentAppConfiguration.templateConfig.configurable_options" v-bind:key="index">
            <v-text-field outlined v-model="currentAppConfiguration.newConfig[`${layer.deterministic_id}`]" dense
                          v-if="layer.type === 'text_input'" :placeholder="layer.placeholder_text"
                           :label="layer.name"></v-text-field>
            <v-switch v-model="currentAppConfiguration.newConfig[`${layer.deterministic_id}`]"
                      class="my-n3"
                      v-if="layer.type === 'toggle'" :label="layer.name" dense></v-switch>
            <v-select v-model="currentAppConfiguration.newConfig[`${layer.deterministic_id}`]"
                      v-if="layer.type === 'select'" :items="layer.possibilities" item-text="name" item-value="value" :label="layer.name"
                      :placeholder="layer.placeholder_text"></v-select>
            <v-radio-group v-if="layer.type === 'radio'" mandatory
                           v-model="currentAppConfiguration.newConfig[`${layer.deterministic_id}`]">
              <v-radio v-for="(item, i) in layer.possibilities" v-bind:key="i" :value="item.value" :label="item.text">
              </v-radio>
            </v-radio-group>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="transparent" @click="appConfigModal = false" depressed
                 class="letter-spacing-normal text-capitalize" width="120">Cancel
          </v-btn>
          <v-btn color="primary" @click="saveAppConfig" depressed class="letter-spacing-normal text-capitalize"
                 width="120">Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-expansion-panels class="elevation-0" accordion>
      <v-expansion-panel v-bind:key="display.uuid" v-for="display in currentDevice.displays"  @click="handlePanelToggle">
        <v-expansion-panel-header class="px-2 pb-0">
          <div class="d-flex align-start">
            <v-icon class="mr-2 text--secondary" size="18">{{ icons.mdiMonitor }}</v-icon>
            <div class="d-flex flex-column justify-center">
              <h3 class="text-primary subtitle-2 mb-1">{{ display.name.split('@')[0] }}
                <span v-if="display.primary" class="text--secondary subtitle-2">|</span>
                {{ display.primary ? 'Primary Display' : '' }}</h3>
              <span v-if="display.applications.length > 0"
                    class="py-2 caption text--secondary">{{ display.graphical_output }} output
                  <span
                      class="font-weight-regular text_color"
                      v-bind:key="application.uuid"
                      v-for="(application) in display.applications">
                        {{ application.name }}
                      </span>
                </span>
              <span
                  v-else
                  class="py-2 caption text-secondary">{{ display.graphical_output }} output: None</span>
            </div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-0 pt-0">
            <v-col
                md="4"
                class="pr-2 pl-0">
              <v-select
                  :value="display.resolution"
                  :items="display.availableResolutions"
                  @change="updateResolution($event, display.uuid)"
                  outlined
                  dense
                  hide-details
                  label="Resolution"
              ></v-select>
            </v-col>
            <v-col
                md="4"
                class="px-2">
              <v-select
                  item-text="text"
                  item-value="value"
                  :value="display.refresh_rate"
                  v-model="display.refresh_rate"
                  @change="updateRefreshRate($event, display.uuid)"
                  :items="display.availableRefreshRates[display.resolution]"
                  outlined
                  dense
                  hide-details
                  label="Refresh rate"
              ></v-select>
            </v-col>
            <v-col
                md="4"
                class="pl-2 pr-0">
              <v-select
                  item-text="text"
                  item-value="value"
                  @change="updateOrientation($event, display.uuid)"
                  v-model="display.orientation"
                  :items="availableOrientations"
                  outlined
                  dense
                  hide-details
                  label="Orientation"></v-select>
            </v-col>
          </v-row>
          <v-row class="mx-n4 mt-1">
            <span class="subtitle-2 text-secondary">Displayed Apps</span>
          </v-row>
          <v-row>
          <v-col class="py-0 px-0 mt-2">
            <v-text-field
                dense
                class="search-bar"
                placeholder="Search"
                outlined
                :append-icon="icons.mdiMagnify"
                v-model="searchApp"
            ></v-text-field>
          </v-col>
        </v-row>
          <v-row class="mt-2 px-0 applications-container">
            <v-col
                md="6"
                class="pt-2 pl-0"
                v-bind:key="app.uuid"
                v-for="(app) in availableApplications">
                <div class="d-flex align-center justify-space-between pa-1 text-break-custom">
                  <v-checkbox
                      hide-details
                      dense
                      class="mt-0 pt-0"
                      color="info"
                      @change="toggleApp(app.uuid, display.uuid)"
                      :input-value="display.applications.find(c => c.uuid === app.uuid)"
                      :label="app.name">
                  </v-checkbox>
                  <v-btn icon width="16px" height="16px" v-if="display.applications.find(c => c.uuid === app.uuid)"
                        @click="configureApp(app.uuid, display.uuid)">
                    <v-icon :size="16">{{ icons.mdiCog }}</v-icon>
                  </v-btn>
              </div>
              <!--                <v-checkbox-->
              <!--                    color="info"-->
              <!--                    @change="updateApp(app.uuid, display.uuid)"-->
              <!--                    :input-value="display.application_uuids.indexOf(app.uuid) > -1"-->
              <!--                    :label="app.text"></v-checkbox>-->
              <!--                <v-btn icon width="16px" height="16px">-->
              <!--                  <v-icon :size="16" @click="openAppConfig(app.value)">mdi-cog</v-icon>-->
              <!--                </v-btn>-->
              <!--              </div>-->
            </v-col>
          </v-row>
          <div class="d-flex ml-n4 mr-n6 mt-2 mb-4 justify-space-between align-center">
            <span class="subtitle-2 font-weight-regular text-secondary">Primary Display</span>
            <v-switch
                :disabled="countDisabledTrue < 2"
                @change="switchPrimaryDisplay($event, display)"
                v-model="display.primary"
                hide-details
                class="mt-0"
                dense
                color="info"></v-switch>
          </div>
          <div class="d-flex ml-n4 mr-n6 mt-2 mb-4 justify-space-between align-center">
            <span class="subtitle-2 font-weight-regular text-secondary">Disable Display</span>
            <v-switch
                :disabled="!display.disabled && countDisabledTrue < 2"
                v-model="display.disabled"
                hide-details
                class="mt-0"
                dense
                @change="switchDisableDisplay($event, display)"
                color="error"></v-switch>
          </div>
          <div class="d-flex mx-n4 my-2 mr-n6 justify-space-between align-center">
            <span class="subtitle-2 font-weight-regular text-secondary">Stream Display (LAN)</span>
            <v-switch
                :disabled="true"
                class="mt-0"
                dense
                hide-details
                color="error"></v-switch>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row class="text-right bottom-navigation-edit py-4 px-2"
     v-bind:style= "[this.$vuetify.theme.dark ? {'background-color': '#1E1E1E'} : {'background-color':'#ffffff'}]"
    ><v-col>
        <v-btn
            width="120"
            class="primary font-weight-normal letter-spacing-normal text-capitalize"
            depressed
            color="primary"
            @click="saveConfiguration"
        >{{ $t('common.save') }}
        </v-btn>
      </v-col>

    </v-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mdiCog, mdiMonitor} from "@mdi/js"
export default {
  props: ['device'],
  name: "PeripheralsDisplays",
  components:{
  
  },
  data: () => ({
    activeDisplay: '',
    searchApp: '',
    currentDevice: {},
    appConfigModal: false,
    currentAppConfiguration: {},
    icons: {
      mdiCog: mdiCog,
      mdiMonitor: mdiMonitor
    }
  }),
  mounted() {
    if (this.device.uuid) {
      this.fetchDisplayData()
    }
  },
  methods: {
    ...mapActions({
      "configurePrimaryDisplay": "devices/configurePrimaryDisplay",
      "updateDisplayResolution": "devices/updateDisplayResolution",
      "updateDisplayRefreshRate": "devices/updateDisplayRefreshRate",
      "updateDisplayOrientation": "devices/updateDisplayOrientation",
      "updateAppConfig": "devices/updateAppConfig",
      "saveDisplayConfiguration": "devices/saveDisplayConfiguration",
      "configureDisableDisplay": "devices/configureDisableDisplay",
      "toggleApplicationForDisplay": "devices/toggleApplicationForDisplay",
      "getDisplays": "devices/getDeviceDisplays",
      "getAvailableApps": "devices/getAvailableApplications",
    }),
    handlePanelToggle() {
        this.searchApp = '';
    },
    toggleApp(app, display) {
      this.toggleApplicationForDisplay({
        appUuid: app,
        displayUuid: display,
        deviceUuid: this.currentDevice.uuid
      });
    },
    configureApp(app, display) {
      let displayIndex = this.currentDevice.displays.findIndex(d => d.uuid === display);
      let appIndex = this.currentDevice.displays[displayIndex].applications.findIndex(a => a.uuid === app);

      this.currentAppConfiguration = {};
      let applicationTemplate = this.currentDevice.available_applications.find(a => a.uuid === app);
      this.currentAppConfiguration = {
        displayUuid: display,
        appUuid: app,
        templateConfig: applicationTemplate.config,
        newConfig: {}
      }
      let oldConfig;
      if (typeof this.currentDevice.displays[displayIndex].applications[appIndex].config === 'string') {
        oldConfig = JSON.parse(this.currentDevice.displays[displayIndex].applications[appIndex].config)
      } else {
        oldConfig = this.currentDevice.displays[displayIndex].applications[appIndex].config;
      }
      this.currentAppConfiguration.templateConfig.configurable_options.forEach(option => {
        this.currentAppConfiguration.newConfig[option.deterministic_id] = oldConfig[option.deterministic_id] || option.default_value;
      });
      this.appConfigModal = true;
    },
    updateResolution(event, display) {
      this.updateDisplayResolution({
        displayUuid: display,
        resolution: event,
        deviceUuid: this.currentDevice.uuid
      });
    },
    updateOrientation(event, display) {
      this.updateDisplayOrientation({
        displayUuid: display,
        orientation: event,
        deviceUuid: this.currentDevice.uuid
      });
    },
    updateRefreshRate(event, display) {
      this.updateRefreshRate({
        displayUuid: display,
        refresh_rate: event,
        deviceUuid: this.currentDevice.uuid
      });
    },
    saveAppConfig() {
      this.updateAppConfig({
        displayUuid: this.currentAppConfiguration.displayUuid,
        appUuid: this.currentAppConfiguration.appUuid,
        config: this.currentAppConfiguration.newConfig,
        deviceUuid: this.currentDevice.uuid
      });
      this.appConfigModal = false;
    },
    saveConfiguration() {
      this.saveDisplayConfiguration(this.currentDevice.uuid);
    },
    switchDisableDisplay(event, display) {
      this.configureDisableDisplay({
        displayUuid: display.uuid,
        value: event,
        deviceUuid: this.currentDevice.uuid
      })
    },
    switchPrimaryDisplay(event, display) {
      this.configurePrimaryDisplay({
        displayUuid: display.uuid,
        value: event,
        deviceUuid: this.currentDevice.uuid
      });
    },
    fetchDisplayData() {
      this.getDisplays(this.device.uuid);
      this.getAvailableApps(this.device.uuid);
    },
  },
  watch: {
    device: {
      immediate: true,
      handler(val) {
        this.currentDevice = val;
      },
      deep: true
    },
  
  },
  computed: {
    ...mapGetters({"availableOrientations": "devices/getAvailableOrientations"}),
    availableApplications() {
    const { searchApp, currentDevice } = this;
    return searchApp !== ""
      ? currentDevice.available_applications.filter(app => 
          app.name.toLowerCase().includes(searchApp.toLowerCase())
        )
      : currentDevice.available_applications;
  },
  countDisabledTrue() {
    let disabledDisplays = this.currentDevice.displays.filter(device => device.disabled).length;
    let totalDisplays = this.currentDevice.displays.length;
    return totalDisplays - disabledDisplays;
  },
  displayDisabledFalse() {
    let disabledDisplays = this.currentDevice.displays.filter(device => !device.disabled);
    return disabledDisplays;
  }

  },
}
</script>

<style scoped lang="scss">
.bottom-navigation-edit {
  position: absolute;
  bottom: 0 !important;
  left: 0;
  right: 0;
  margin: 0;
  box-shadow: 0 -4px 25px 0 rgba(0, 0, 0, 0.13);
  z-index: 900 !important;
}
.text_color{
  color: var(--v-primary-base) !important;
}
@media (max-width: 600px) {
  .bottom-navigation-edit {
    position: fixed;
    bottom: 0 !important;
  }
}
.text-break-custom {
  word-break: break-all;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .text-break-custom {
    word-break: normal; /* Adjust to 'break-word' if needed */
  }
}



</style>
