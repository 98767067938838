<template>
  <div class="alert--bottom-fixed d-flex flex-column align-center justify-center ml-n3 px-2">
    <v-alert
        dense
        prominent
        v-for="alert in alerts"
        v-bind:key="alert.uuid"
        :type="alert.type || 'success'"
        :color="alert.color"
        class="responsive-alert" 
    >
      <v-row no-gutters class="d-flex align-center">
        <v-col :cols="alert.button ? 5 : 12">
          {{ alert.message }}
        </v-col>
        <v-col v-if="alert.button" class="d-flex align-center justify-end" cols="3" offset="4">
          <v-btn
              class="letter-spacing-normal text-capitalize mr-2"
              depressed
              text
              @click="closeAlert(alert)">{{ $t('common.close') }}
          </v-btn>
          <v-btn
              depressed
              light
              color="primary"
              class="text-capitalize font-weight-regular letter-spacing-normal"
              @click="triggerButton(alert)">{{ alert.button.text }}
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>

  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import isMobile from "../mixins/isMobile";

export default {
  mixins: [isMobile],
  computed: {
    ...mapGetters({alerts: "global/getAlerts"}),
  },
  data: () => ({
    currentAlerts: [],
    emptyUrl: "",
  }),
  methods: {
    ...mapActions({
      setDeviceScreenshot: "devices/setDeviceScreenshot",
      removeAlert: "global/removeAlert",
    }),
    triggerButton(alert) {
      if (alert.button.type === 'screenshot') {
        this.setDeviceScreenshot(alert.button.data);
      } else if (alert.button.type === 'copy') {
        navigator.clipboard.writeText(alert.button.data);
      } else {
        window.open(alert.button.data, "_blank");

      }
      this.removeAlert(alert.uuid)
    },
    closeAlert(alert) {
      this.removeAlert(alert.uuid)
    },
    reinitializeTimers() {
      this.currentAlerts.forEach((alert, i) => {

        if (!alert.constant && !alert.duration) {
          alert.duration = 3000;
        }

        if (alert.constant) {
          return;
        } else {
          this.currentAlerts[i].timer = setTimeout(() => {
            this.removeAlert(alert.uuid);
          }, alert.duration)
        }
      });
    },
  },
  watch: {
    alerts: {
      handler(val) {
        this.currentAlerts = val;
        this.reinitializeTimers();
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.responsive-alert {
  min-width: 600px; /* Default width for larger screens */
  pointer-events: visible !important;;
}


@media (max-width: 600px) {
  .responsive-alert {
    min-width: 100%; /* Full width for screens up to 600px */
  }
}
</style>
