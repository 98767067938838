<template>
  <v-row class="fill-height scroll-container" ref="scrollContainer" v-on:scroll="handleScroll">
    <v-col md="8" class="pr-4 content-height overflow-y-auto">
      <br>
      <span class="Associated-Devices"> Associated Devices </span>
      <br>
      <v-row class="px-2 mt-5">
        <v-col
          cols="12"
          sm="12"
          md="6"
          lg="6"
          xl="6"
          class="px-2 pb-4 pt-0"
          v-bind:key="item.uuid"
          v-for="item in getDevicesByLocation">
          <LocationDevices :data="item" :type="item.device_type_shorthand" />
        </v-col>
      </v-row>
    </v-col>
    <v-divider class="d-none d-md-flex" vertical />
    <v-col md="4" class="ml-n3 py-0 pr-0 content-height overflow-y-auto">
      <v-card height="72" class="px-4 pb-5 pt-3" flat>
        <v-card-text class="pa-0">
          <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">
            Associated devices
          </span>
          <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
            {{ location.total_number }}
          </v-list-item-title>
        </v-card-text>
      </v-card>
      <v-divider />
      <v-card height="72" class="px-4 pb-5 pt-3" flat>
        <v-card-text class="pa-0">
          <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">
            Structure
          </span>
          <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
            {{ countConnected }} Connected, {{ countDisconnected }} Disconnected,
            <br />
            {{ countNonConnected }} Non-Connected, {{  countArchived }} Archived.
          </v-list-item-title>
        </v-card-text>
      </v-card>
      <v-divider />
      <v-card height="72" class="px-4 pb-5 pt-3" flat>
        <v-card-text class="pa-0">
          <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">
            Archived devices
          </span>
          <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
            {{ countArchivedDevices === 0 ? 'none' : countArchivedDevices }}
          </v-list-item-title>
        </v-card-text>
      </v-card>
      <v-divider />
      <v-card height="72" class="px-4 pb-5 pt-3" flat>
        <v-card-text class="pa-0">
          <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">
            Latest Associated
          </span>
          <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
            {{ getLatestDevice }}
          </v-list-item-title>
        </v-card-text>
      </v-card>
      <v-divider />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LocationDevices from "../LocationDevices";

export default {
  name: "locationDevicesList",
  components: {
    LocationDevices,
  },
  props: ["location"],
  mounted: function() {
    this.getDevices({
      space_uuid: this.$props.location.space_uuid,
      location_uuid: this.$props.location.uuid,
    });
  },
  data() {
    return {
      scrollTimeout: null,
    };
  },
  computed: {
    ...mapGetters("locations", ["getLinksLocations", "getDevicesByLocation"]),
  
    getLatestDevice(){
      return this.getDevicesByLocation[0].name
    },
    countConnected() {
      return this.location.total_connected_number;
    },
    countDisconnected() {
      return this.location.total_disconnected_number;
    },
    countNonConnected() {
      return this.location.total_non_connected_number;
    },
    countArchived(){
      return this.location.total_archived_number
    },
    countArchivedCameras(){
      return this.location.archived_cameras_number
    },
    countArchivedDevices(){
      return this.location.archived_devices_number
    },
    
  },
  watch: {
    getDevicesByLocation: {
      handler() {
        this.devices = this.getDevicesByLocation;
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      getDevices: "locations/getLocationDevices",
      getSpecificDevice: "devices/getSpecificDevice",
    }),
    debouncedHandleScroll() {
      if (this.scrollTimeout) {
        clearTimeout(this.scrollTimeout);
      }
      this.scrollTimeout = setTimeout(this.handleScroll, 200);
    },
    handleScroll() {
      const container = this.$refs.scrollContainer;
      const bottomOfContainer = container.scrollHeight - container.scrollTop <= container.clientHeight + 100;
      if (bottomOfContainer && this.getLinksLocations.next) {
        let nextNumber = this.extractPageFromLinkNext();
        this.$store.commit("locations/SET_PAGE_NUMBER", nextNumber);
      }
    },
    extractPageFromLinkNext() {
      const url = this.getLinksLocations.next;
      const parsedUrl = new URL(url);
      const page = parsedUrl.searchParams.get("page");
      return page;
    },
  },
};
</script>

<style scoped>
.scroll-container {
  height: 100%; /* Set the desired height */
  overflow-y: auto; /* Enable vertical scrollbar */
}
.Associated-Devices {
  width: 608px;
  height: 19px;
  margin: 0 0 24px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--v-secondaryText-base);
}
</style>
