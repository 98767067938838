<template>
  <v-row v-if="location" class="fill-height">
    <v-col md="8" class="pt-10 pr-4 content-height overflow-y-auto">
      <span class="body-text text-primary letter-spacing-normal">
        {{ location.description }}
      </span>
      <GmapMap
          :options="map.options"
          :center="map.center"
          :zoom="map.zoom"
          style="
          width: 100%;
          height: 278px;
          border: solid 1px #dedede;
          border-radius: 4px;
        ">
        <GmapMarker
            :position="{
            lat: location.latitude ? map.pin.position.lat : 43.3438,
            lng: location.longitude ? map.pin.position.lng : 17.8078,
          }"
            :clickable="true"
            :draggable="false"
            @click="map.center"
        />
      </GmapMap>
      <div class="mt-4">
        <v-chip v-for="tag in location.tags" v-bind:key="tag.uuid" small class="mt-2 mr-2" style="border-radius: 4px !important">{{ tag.name }}</v-chip>
      </div>
    </v-col>
    <v-divider class="d-none d-md-flex" vertical/>
    <v-col cols="12" sm="12" md="4" class="ml-n3 py-0 pr-0 content-height overflow-y-auto" style="display: flex; flex-direction: column;">
      <v-card height="72" class="px-4 pb-5 pt-3" flat>
        <v-card-text class="pa-0">
          <span
              class="subtitle-2 mb-2 text-secondary font-weight-regular d-block"
          >{{$t('assets.locations.locationsGeneral.type')}}</span
          >
          <v-list-item-title
              class="subtitle-2 text-primary font-weight-regular"
          >
            {{ location.location_type_name }}
          </v-list-item-title>
        </v-card-text>
      </v-card>
      <v-divider/>
      <v-card height="72" class="px-4 pb-5 pt-3" flat>
        <v-card-text class="pa-0">
          <span
              class="subtitle-2 mb-2 text-secondary font-weight-regular d-block"
         >{{$t('assets.locations.locationsGeneral.country')}}</span
          >
          <v-list-item-title
              class="subtitle-2 text-primary font-weight-regular"
          >
            {{ location.country_name }}
          </v-list-item-title>
        </v-card-text>
      </v-card>
      <v-divider/>
      <v-card height="72" class="px-4 pb-5 pt-3" flat>
        <v-card-text class="pa-0">
          <span
              class="subtitle-2 mb-2 text-secondary font-weight-regular d-block"
          >{{$t('assets.locations.locationsGeneral.city')}}</span
          >
          <v-list-item-title
              class="subtitle-2 text-primary font-weight-regular"
          >
            {{ location.city }}
          </v-list-item-title>
        </v-card-text>
      </v-card>
      <v-divider/>
      <v-card height="72" class="px-4 pb-5 pt-3" flat>
        <v-card-text class="pa-0">
          <span
              class="subtitle-2 mb-2 text-secondary font-weight-regular d-block"
          >{{$t('assets.locations.locationsGeneral.address')}}</span
          >
          <v-list-item-title
              class="subtitle-2 text-primary font-weight-regular"
          >
            {{ location.address }}
          </v-list-item-title>
        </v-card-text>
      </v-card>
      <v-divider/>
      <v-card height="72" class="px-4 pb-5 pt-3" flat>
        <v-card-text class="pa-0">
          <span
              class="subtitle-2 mb-2 text-secondary font-weight-regular d-block"
              >{{$t('assets.locations.locationsGeneral.zipCode')}}</span
          >
          <v-list-item-title
              class="subtitle-2 text-primary font-weight-regular"
          >
            {{ location.zip_code }}
          </v-list-item-title>
        </v-card-text>
      </v-card>
      <v-divider/>
      <v-card height="72" class="px-4 pb-5 pt-3" flat>
        <v-card-text class="pa-0">
          <span
              class="subtitle-2 mb-2 text-secondary font-weight-regular d-block"
              >{{$t('assets.locations.locationsGeneral.geolocation')}}</span
          >
          <v-list-item-title
              class="subtitle-2 text-primary font-weight-regular"
          >
            {{ !location.latitude && !location.longitude ? 'None': location.latitude + ', ' + location.longitude }}
          </v-list-item-title>
        </v-card-text>
      </v-card>
      <v-divider/>
      <v-card height="72" class="px-4 pb-5 pt-3" flat>
        <v-card-text class="pa-0">
          <span
              class="subtitle-2 mb-2 text-secondary font-weight-regular d-block"
              >{{$t('assets.locations.locationsGeneral.addedOn')}}</span
          >
          <v-list-item-title
              class="subtitle-2 text-primary font-weight-regular"
          >
            {{ location.created_at }}
          </v-list-item-title>
        </v-card-text>
      </v-card>
      <v-divider/>
      <v-card height="72" class="px-4 pb-5 pt-3" flat>
        <v-card-text class="pa-0">
          <span
              class="subtitle-2 mb-2 text-secondary font-weight-regular d-block"
          >{{$t('assets.locations.locationsGeneral.uuid')}}</span
          >
          <v-list-item-title
              class="subtitle-2 text-primary font-weight-regular"
          >
            {{ location.uuid }}
          </v-list-item-title>
        </v-card-text>
      </v-card>
      <v-divider/>
      <v-card height="72" class="px-4 pb-5 pt-3" flat>
        <v-card-text class="pa-0">
          <span
              class="subtitle-2 mb-2 text-secondary font-weight-regular d-block"
          >{{$t('assets.locations.locationsGeneral.onlineDevices')}}</span
          >
          <v-list-item-title
              class="subtitle-2 text-primary font-weight-regular"
          >
            {{ "Yes" + "(" + location.total_online_number + "/" + location.total_number + ")" }}
          </v-list-item-title>
        </v-card-text>
      </v-card>
      <v-divider/>
      <v-card height="72" class="px-4 pb-5 pt-3" flat>
        <v-card-text class="pa-0">
          <span
              class="subtitle-2 mb-2 text-secondary font-weight-regular d-block"
          >{{$t('assets.locations.locationsGeneral.onlineAccounts')}}</span
          >
          <v-list-item-title
              class="subtitle-2 text-primary font-weight-regular"
          >Yes(4/8)
          </v-list-item-title>
        </v-card-text>
      </v-card>
      <v-divider/>
      <v-card class="px-4 pb-5 pt-3" flat style="height: auto;">
        <v-card-text class="pa-0">
            <span class="subtitle-2 text-secondary font-weight-regular d-block mb-4">{{$t('assets.locations.locationsGeneral.locationDisplayId')}}</span>
            <v-list-item-title class="subtitle-2 text-primary font-weight-regular" style="white-space: pre-wrap;">
              {{ location.display_id }}
            </v-list-item-title>
          </v-card-text>
        </v-card>
      <v-divider/>
      <v-card class="px-4 pb-5 pt-3" flat style="height: auto;">
        <v-card-text class="pa-0">
            <span class="subtitle-2 text-secondary font-weight-regular d-block mb-4">Location Timezone</span>
            <v-list-item-title class="subtitle-2 text-primary font-weight-regular" style="white-space: pre-wrap;">
              {{ location.timezone === location.space_timezone ? $t('assets.locations.locationsGeneral.globalTimezone') : location.timezone }}
            </v-list-item-title>
          </v-card-text>
        </v-card>
      <v-divider/>
    </v-col>
  </v-row>
</template>
<script>
import isMobile from "../../mixins/isMobile";

export default {
  name: "LocationInfoGeneral",
  props: ["location"],
  mixins: [isMobile],
  mounted() {
    this.map.pin.position = {
      lat: parseFloat(this.location.latitude),
      lng: parseFloat(this.location.longitude),
    };
  },
  data: () => ({
    map: {
      pin: {
        position: {
          lat: 0,
          lng: 0,
        },
      },
      center: {
        lat: 43.3438,
        lng: 17.8078,
      },
      zoom: 7,
      options: {
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        scaleControl: false,
        zoomControl: true,
        styles: [
          {
            featureType: "water",
            stylers: [
              {
                visibility: "on",
              },
              {
                color: "#b5cbe4",
              },
            ],
          },
          {
            featureType: "landscape",
            stylers: [
              {
                color: "#efefef",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [
              {
                color: "#83a5b0",
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "geometry",
            stylers: [
              {
                color: "#bdcdd3",
              },
            ],
          },
          {
            featureType: "road.local",
            elementType: "geometry",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [
              {
                color: "#e3eed3",
              },
            ],
          },
          {
            featureType: "administrative",
            stylers: [
              {
                visibility: "on",
              },
              {
                lightness: 33,
              },
            ],
          },
          {
            featureType: "road",
          },
          {
            featureType: "poi.park",
            elementType: "labels",
            stylers: [
              {
                visibility: "on",
              },
              {
                lightness: 20,
              },
            ],
          },
          {},
          {
            featureType: "road",
            stylers: [
              {
                lightness: 20,
              },
            ],
          },
        ],
      },
    },
  }),
  methods: {
  convertToLocalTime(utcDateString) {
      // Create a Date object from the UTC timestamp
      const date = new Date(utcDateString);
      const localTime = date.toLocaleString();
      return localTime;
    }
  }
};
</script>

<style scoped>
</style>
