<template>
  <div>
    <v-overlay class="text-center" opacity="0.78" v-if="qrInstalling">
      <v-progress-circular :size="50" color="white" indeterminate></v-progress-circular>
    </v-overlay>
    <v-row
        v-if="device"
        class="fill-height">
      <v-col
          v-if="device.connected_status === 'connected'"
          md="8"
          class="pt-10 pr-4 content-height overflow-y-auto">
        <span class="body-text text-primary letter-spacing-normal">
          {{ $t(`assets.devices.deviceInfo.typeDescriptions.${device.device_type_name}`) }}
        </span>
        <div class="mt-4">
          <v-chip v-for="tag in device.tags" v-bind:key="tag.uuid" small class="mt-2 mr-2" style="border-radius: 4px !important">{{ tag.name }}</v-chip>
        </div>
      </v-col>
      <v-col
          v-else
          md="8"
          class="pt-10 pr-4 content-height overflow-y-auto">
        <v-card
            v-if="device.connected_status === 'installation_pending'"
            flat
            class="pa-6 install_pending_card">
          <h3 class="subtitle-1 font-weight-bold mb-4 text-primary">Connection pending...</h3>
          <h4 class="body-text mb-4 text-primary">
            Device is currently in the connection pending state. Once it is
            connected successfully, it will become configurable. This state is usually caused by unexpected
            internet problems during device connection initialization.</h4>
          <h4 class="body-text text-primary">If you wish to stop the existing connection process and start again,
            simply click the “Terminate Connection” button down below.</h4>
          <div class="d-flex justify-end">
            <v-btn
                depressed
                @click="terminateConnection(device)"
                color="warning"
                class="subtitle-2 mt-8 text-capitalize px-8">Terminate Connection
            </v-btn>
          </div>
        </v-card>
        <v-card  
        flat
        class="px-6 py-5 background"
        v-else-if="device.connected_status === 'archived'">
          <p>The device has been consigned to the archives. To procure the installation code, the device must undergo an unarchiving process.</p>
        </v-card>
        <v-card
            v-else
            flat
            v-bind:class="installationCodeExpired ? 'expired-bg': 'background'"
            class="px-6 py-5 background">
          <p
              style="line-height: normal !important"
              class="pt-1 text-primary"
              v-if="!installationCodeIsReset && !installationCodeSent">{{
              installationCodeExpired ?
                  $t('assets.devices.deviceInfo.installationCode.codeExpiredMessage') :
                  $t('assets.devices.deviceInfo.installationCode.codeMessage')
            }}</p>
          <span
              class="body-text text--primary"
              v-if="installationCodeIsReset && !installationCodeSent">{{
              $t('assets.devices.deviceInfo.installationCode.codeResetMessage')
            }}</span>
          <span
              class="body-text text--primary"
              v-if="installationCodeSent">{{
              $t('assets.devices.deviceInfo.installationCode.codeEmailSentMessage')
            }}<span class="font-weight-bold">{{ installationCodeEmail }}</span></span>
          <p
              class="body-text mt-8 text--primary"
              v-if="installationCodeSent">
            {{ $t('assets.devices.deviceInfo.installationCode.codeEmailSentInfo') }}</p>
          <div
              class="d-flex align-center pt-1"
              v-if="!installationCodeSent">
            <v-row>
              <v-col>
                <div style="width: 242px">
                  <v-text-field
                      ref="installationCode"
                      hide-details
                      v-bind:class="installationCodeExpired ? 'crossed' : ''"
                      class="centered-input"
                      outlined
                      :append-icon="icons.mdiContentCopy"
                      readonly
                      dense
                      @click:append="copyValue()"
                      :value="installationCodeValue ? beautifyInstallationCode(installationCodeValue) : null"></v-text-field>
                </div>
                <span class="subtitle-2 ml-5">{{
                    this.installationCodeExpired ? "Expired" : $t('assets.devices.deviceInfo.installationCode.expiresIn')
                  }} {{ expiredTime }}</span>
              </v-col>
              <v-col>
                <v-btn
                    depressed
                    v-bind:color="installationCodeExpired?'primary':'background'"
                    height="40"
                    class="subtitle-2 full"
                    @click="resetCode(device.uuid)">
                  {{ $t('assets.devices.deviceInfo.installationCode.resetCodeBtn') }}
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                    dense
                    depressed
                    height="40"
                    color="primary"
                    @click="QRScanner = true"
                    v-if="isMobile()">Scan QR
                </v-btn>
              </v-col>
            </v-row>
            <v-dialog v-model="QRScanner">
              <v-card class="pa-4">
                <h2 class="title text-primary">Scan your QR Code</h2>
                <h4 class="subtitle-1 text-secondary">Point your camera to the QR Code displayed on Code
                  Connect app</h4>
                <qrcode-stream
                    v-if="QRScanner"
                    @init="onInit"
                    @decode="onDecode"></qrcode-stream>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="text-primary"
                      text
                      @click="QRScanner = false">
                      {{ $t('common.close') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <div
              v-if="!installationCodeExpired"
              class="mt-5">
            <p
                class="body-text"
                style="letter-spacing: normal !important; line-height: normal"
                v-if="!installationCodeSent">
              {{ $t('assets.devices.deviceInfo.installationCode.codeInfoWhenUsed') }}</p>
            <p
                class="body-text pt-2"
                style="letter-spacing: normal !important; line-height: normal"
                v-if="!installationCodeSent">
              {{ $t('assets.devices.deviceInfo.installationCode.codeInfoSendEmail') }}</p>
            <v-text-field
                class="pt-6"
                dense
                outlined
                label="Email"
                v-model="installationCodeEmail"></v-text-field>
            <v-textarea
                class="mb-16 pt-4"
                outlined
                hint="You don't need to write code here. It will automatically be sent"
                v-model="installationCodeMessage"></v-textarea>
            <v-row class="d-flex justify-end pr-2">

              <v-btn
                  depressed
                  color="primary"
                  class="text-capitalize mt-3"
                  style="width: 116px"
                  @click="sendEmail(device.uuid)">{{ $t('common.sendBtn') }}
              </v-btn>
            </v-row>
          </div>
        </v-card>
      </v-col>
      <!-- TODO: Research this bug with height-->
      <v-divider
          class="d-none d-md-flex"
          vertical style="height: 100vh"/>
      <!-- END TODO -->
      <v-col
          cols="12"
          sm="12"
          md="4"
          class="ml-n3 py-0 pr-0 content-height overflow-y-auto">
        <v-card
            height="72"
            class="px-4 pb-5 pt-3 "
            flat>
          <v-card-text class="pa-0">
            <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">{{
                $t('assets.devices.deviceInfo.info.deviceCategoryLabel')
              }}</span>
            <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
              {{ device.device_category_name }}
            </v-list-item-title>
          </v-card-text>
        </v-card>
        <v-divider/>
        <v-card
            height="72"
            class="px-4 pb-5 pt-3"
            flat>
          <v-card-text class="pa-0">
            <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">{{
                $t('assets.devices.deviceInfo.info.deviceTypeLabel')
              }}</span>
            <v-list-item-title class="subtitle-2 text-primary font-weight-regular">{{ device.device_type_name }}
            </v-list-item-title>
          </v-card-text>
        </v-card>
        <v-divider/>
        <v-card
            height="72"
            class="px-4 pb-5 pt-3"
            flat>
          <v-card-text class="pa-0">
            <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">{{
                $t('assets.devices.deviceInfo.info.connectionStatusLabel')
              }}</span>
            <v-chip
                small
                style="border-radius: 4px !important; height: 16px;"
                text-color="white"
                :color="device.online ? 'success' : 'error'">{{
                device.online ?
                    $t('assets.devices.deviceInfo.info.connectionStatusOnline') :
                    $t('assets.devices.deviceInfo.info.connectionStatusOffline')
              }}
            </v-chip>
          </v-card-text>
        </v-card>
        <v-divider/>
        <v-card
            height="72"
            class="px-4 pb-5 pt-3"
            flat>
          <v-card-text class="pa-0">
            <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">{{
                $t('assets.devices.deviceInfo.info.deviceStatusLabel')
              }}</span>
            <v-list-item-title class="subtitle-2 text-primary font-weight-regular">{{
                device.enabled ?
                    $t('assets.devices.deviceInfo.info.deviceStatusEnabled') :
                    $t('assets.devices.deviceInfo.info.deviceStatusDisabled')
              }}
            </v-list-item-title>
          </v-card-text>
        </v-card>
        <v-divider v-show="this.$props.device.connected_status !== 'non_connected'" />
        <v-card
            v-show="this.$props.device.connected_status !== 'non_connected'"
            height="72"
            class="px-4 pb-5 pt-3"
            flat>
          <v-card-text class="pa-0">
            <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">
              {{
                device.online ? $t('assets.devices.deviceInfo.info.onlineStreakLabel'):
                $t('assets.devices.deviceInfo.info.offlineStreakLabel')
              }}</span>
            <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
              {{ onlineStreak  }}
            </v-list-item-title>
          </v-card-text>
        </v-card>
        <v-divider/>
        <v-card
            height="72"
            class="px-4 pb-5 pt-3"
            flat>
          <v-card-text class="pa-0">
            <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">{{
                $t('assets.devices.deviceInfo.info.locationLabel')
              }}</span>
            <v-list-item-title class="subtitle-2 text-primary font-weight-regular">{{ device.location_name }}
            </v-list-item-title>
          </v-card-text>
        </v-card>
        <v-divider/>
        <v-card
            height="72"
            class="px-4 pb-5 pt-3"
            flat>
          <v-card-text class="pa-0">
            <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">{{
                $t('assets.devices.deviceInfo.info.addedOnLabel')
              }}</span>
            <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
              {{ beautifyDate(device.created_at) }}
            </v-list-item-title>
          </v-card-text>
        </v-card>
        <v-divider/>
        <v-card
            height="72"
            class="pl-4 pr-0 pb-5 pt-3"
            flat>
          <v-card-text class="pa-0">
            <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">{{
                $t('assets.devices.deviceInfo.info.uuidLabel')
              }}</span>
            <v-list-item-title class="subtitle-2 text-primary font-weight-regular">{{ device.uuid }}
            </v-list-item-title>
          </v-card-text>
        </v-card>
        <v-divider/>
        <v-card
            height="72"
            class="pl-4 pr-0 pb-5 pt-3"
            flat>
          <v-card-text class="pa-0">
            <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">{{
                $t('assets.devices.deviceInfo.info.machineID')
              }}</span>
            <v-list-item-title class="subtitle-2 text-primary font-weight-regular">{{ device.machine_id ? device.machine_id : 'none' }}
            </v-list-item-title>
          </v-card-text>
        </v-card>
        <v-divider/>
         <v-card
            height="72"
            class="pl-4 pr-0 pb-5 pt-3"
            flat>
          <v-card-text class="pa-0">
            <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">{{
                $t('assets.devices.deviceInfo.info.ipAddressLabel')
              }}</span>
            <v-list-item-title v-for="item  in device.local_ip_addresses" :key="item" class="subtitle-2 text-primary font-weight-regular">{{ item }}
            </v-list-item-title>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import DevicesApi from '@/services/api/Devices';
import isMobile from '@/mixins/isMobile';
import {QrcodeStream} from 'vue-qrcode-reader';
import {format, formatDistanceToNow, isPast} from "date-fns";
import {mdiContentCopy} from "@mdi/js";
import { mapActions, mapGetters } from 'vuex';


export default {
  props: ['device'],
  components: {
    QrcodeStream,
  },
  mixins: [isMobile],
  data: () => ({
    QRScanner: false,
    QRValue: '',
    qrInstalling: false,
    lastSeen: null,
    onlineStreak: null,
    installationCodeMessage: '',
    installationCodeEmail: '',
    installationCodeValue: '',
    installationCodeExpiresAt: {},
    installationCodeExpiresIn: null,
    installationCodeExpired: false,
    installationCodeIsReset: false,
    installationCodeSent: false,
    icons: {
      mdiContentCopy: mdiContentCopy,
    },
    loaders: {
      'instant-reboot': false,
      'instant-power-off': false,
    },
    disabled: {
      'instant-reboot': false,
      'instant-power-off': false,
    },
  }),
  watch: {
    device: {
      deep: true,
      handler(val) {
        if (val.online === false) {
          Object.keys(this.disabled).forEach(v => this.disabled[v] = true);
        } else {
          Object.keys(this.disabled).forEach(v => this.disabled[v] = false);
        }
      }
    },
    getDevice: {
      handler(newVal) {
        if (newVal) {
          this.updateDeviceDetails(newVal);
        }
      },
      deep: true,
      immediate: true // Ensures the watcher runs immediately after the component is mounted
    }
  },
  mounted() {
    this.updateDeviceDetails(this.device);
    if (this.$props.device.online === false) {
      Object.keys(this.disabled).forEach(v => this.disabled[v] = true);
    } else {
      Object.keys(this.disabled).forEach(v => this.disabled[v] = false);
    }
    this.installationCodeValue = this.$props.device.installation_code_value;
    if (this.$props.device.installation_code_expires_at) {
      this.installationCodeExpiresAt = new Date(this.$props.device.installation_code_expires_at);
      this.installationCodeExpiresIn = formatDistanceToNow(this.installationCodeExpiresAt, {
        addSuffix: true,
      });
      if (isPast(this.installationCodeExpiresAt)) {
        this.installationCodeExpired = true;
      }
    }

    this.calculateStreak()
    setInterval(() => {
      this.calculateStreak()
    }, 1000);
  },

  methods: {
    ...mapActions({terminateConnection: "devices/terminateConnection"}),
    calculateExpiration(expiryDate) {
            if (!expiryDate) return null;
            return formatDistanceToNow(new Date(expiryDate), { addSuffix: true });
        },
        calculateStreak() {
      this.lastSeen = new Date(this.$props.device['last_seen']);
      const now = new Date();

      // Get the difference in milliseconds
      const diffInMs = now - this.lastSeen;

      // Convert the difference into total seconds
      const totalSeconds = Math.floor(diffInMs / 1000);

      // Calculate hours, minutes, and seconds
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;

      // Format the streak as HH:mm:ss
      this.onlineStreak = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

      console.log('Calculated Streak:', this.onlineStreak); // Log the streak
    },
    updateDeviceDetails(device) {
        if (device) {
            this.installationCodeValue = device.installation_code_value;
            this.installationCodeExpiresAt = new Date(device.installation_code_expires_at);
            this.installationCodeExpiresIn = this.calculateExpiration(device.installation_code_expires_at);
            this.installationCodeExpired = isPast(this.installationCodeExpiresAt);
        }
    },
    async onInit(promise) {
      try {
        await promise
      } catch (error) {
        console.log(error)
        if (error.name === 'NotAllowedError') {
          this.qrError = "ERROR: you need to grant camera access permisson"
        } else if (error.name === 'NotFoundError') {
          this.qrError = "ERROR: no camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.qrError = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.qrError = "ERROR: is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.qrError = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.qrError = "ERROR: Stream API is not supported in this browser"
        }
      }
    },
    installFromQRCode() {
      this.QRScanner = false;
      this.qrInstalling = true;
      DevicesApi.installFromQRCode(this.$props.device.space_uuid, this.$props.device.uuid, {
        'unclaimed_device_uuid': this.QRValue,
      }).then(() => {
        this.$store.commit('updateAlert', {
          type: 'success',
          shown: true,
          message: `Code valid. Device will install in a few moments.`,
        });
      }).catch(err => {
        this.$store.commit('updateAlert', {
          type: 'error',
          shown: true,
          message: `Failed to install from QR code. Check console.`,
        });
        console.log(err);
      }).finally(() => {
        this.qrInstalling = false;
      });
    },
    onDecode(value) {
      this.QRValue = value;
      this.installFromQRCode();
    },
    resetCode(uuid) {
    DevicesApi.resetCode(this.$props.device.space_uuid, uuid, {}).then(response => {
        this.installationCodeValue = response.data.installation_code_value;
        this.installationCodeExpiresAt = new Date(response.data.installation_code_expires_at);
        this.installationCodeExpiresIn = this.calculateExpiration(this.installationCodeExpiresAt); // Recalculate the expiration
        this.installationCodeExpired = false; // Update the expired status
        this.installationCodeResetted = true; // Indicate that the code has been reset
    }).catch(err => {
        this.$store.commit('updateAlert', {
            type: 'error',
            shown: true,
            message: `Failed to reset code. Check console.`,
        });
        console.error(err);
    });
},

    sendEmail(uuid) {
      DevicesApi.sendEmail(this.$props.device.space_uuid, uuid, {
        email_address: this.installationCodeEmail,
        email_message: this.installationCodeMessage,
      }).then(() => {
        this.$store.commit('updateAlert', {
          type: 'success',
          shown: true,
          message: `Email sent`,
        });
        this.installationCodeSent = true;
      }).catch(err => {
        this.$store.commit('updateAlert', {
          type: 'error',
          shown: true,
          message: `Failed to send email. Check console.`,
        });
        console.log(err);
      });
    },
    beautifyInstallationCode(value) {
      return value.match(/.{1,4}/g).join('-');
    },
    beautifyDate(date) {
      return date ? format(new Date(date), 'dd MMM yyyy HH:mm:ss') : "";
    },
    copyValue() {
      this.$refs['installationCode'].$el.querySelector('input').select();
      document.execCommand('copy');
    },
  },
  computed: {
    ...mapGetters({getDevice: 'devices/getSelectedDevice'}),
  },
};
</script>

<style scoped>

</style>
