<template>
  <div>
    <v-row>
      <v-col class="py-2">
        <FlyoutMenuHead
            :selectedItem="getSelectedLocation"
            type="edit"
            route="locations"/>
      </v-col>
    </v-row>
    <v-divider/>
    <v-row class="mt-4">
      <v-col md="12" class="d-flex align-center justify-space-between pt-4">
        <span class="subtitle text-secondary">{{ $t('assets.locations.locationsInfoEdit.title') }}</span>
      </v-col>
    </v-row>
    <v-form
    class="overflow-y-auto scroll-right-md-y scroll-right-lg-y"
    @submit.prevent="editLocation()">
      <v-row>
        <v-col md="8" class="d-flex align-center justify-space-between pb-0">
          <v-text-field
              v-model="location.name"
              @input="changeInput('name')"
              :maxlength="256"
              :counter="256"
              height="40"
              placeholder="Friendly name for your location"
              :rules="[rules.required]"
              :error-messages="fieldErrors.name || []"
              dense
              outlined
          >
            <template #label>
              <span class="pl-1 pr-1">{{ $t('assets.locations.locationsInfoEdit.locationName') }}</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="8">
          <v-text-field
              v-model="location.display_id"
              @input="changeInput('display_id')"
              :maxlength="256"
              :counter="256"
              height="40"
              placeholder="enter display id for location"
              :rules="[rules.required]"
              :error-messages="fieldErrors.display_id || []"
              dense
              outlined
      
          >
            <template #label>
              <span class="pl-1 pr-1">{{$t('assets.locations.locationsGeneral.locationDisplayId')}}</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="px-1">
        <v-col md="4" class="d-flex align-center justify-space-between pl-0">
          <v-select
              :append-icon="$vuetify.icons.values.chevronDown"
              :items="countries.countries"
              @input="changeInput('country_code')"
              v-model="location.country_code"
              class="ml-2 mt-n3"
              placeholder="Country"
              :rules="
              this.location.location_type_uuid
                ? [rules.required]
                : []
            "
              :error-messages="fieldErrors.country_code || []"
              outlined
              dense
              height="40"
              item-text="name"
              item-value="code"
              @change="updateSearch()"
          >
            <template #label>
              <span class="pl-1 pr-1">{{$t('assets.locations.locationsGeneral.country')}}</span>
            </template>
          </v-select>
        </v-col>
        <v-col md="4" class="d-flex align-center justify-space-between pr-0">
          <v-text-field
              v-model="location.city"
              @input="changeInput('city')" 
              class="mr-2 mt-n3"
              :rules="
              this.location.city
                ? [rules.required]
                : []
            "
              :error-messages="fieldErrors.city || []"
              height="40"
              dense
              outlined
              @change="updateSearch()"
              placeholder="City"
          >
          <template #label>
              <span class="pl-1 pr-1">{{$t('assets.locations.locationsGeneral.city')}}</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="px-1">
        <v-col md="4" class="d-flex align-center justify-space-between pl-0">
          <v-text-field
              v-model="location.address"
              @input="changeInput('address')"
              class="ml-2 mt-n3"
              :rules="
              this.location.address
                ? [rules.required]
                : []
            "
              :error-messages="fieldErrors.address || []"
              height="40"
              dense
              outlined
              @change="updateSearch()"
              placeholder="Address"
              label=""
          >
          <template #label>
              <span class="pl-1 pr-1">{{$t('assets.locations.locationsGeneral.address')}}</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col md="4" class="d-flex align-center justify-space-between pr-0">
          <v-text-field
              v-model="location.zip_code"
              @input="changeInput('zip_code')"
              class="mr-2 mt-n3"
              height="40"
              :rules="
              this.location.zip_code
                ? [rules.required]
                : []
            "
              :error-messages="fieldErrors.zip_code || []"
              dense
              outlined
              @change="updateSearch()"
              placeholder="Zip Code"
              label=""
          >
          <template #label>
              <span class="pl-1 pr-1">{{$t('assets.locations.locationsGeneral.zipCode')}}</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="px-1">
        <v-col md="4" class="d-flex align-center justify-space-between pl-0">
          <v-select
          :append-icon="$vuetify.icons.values.chevronDown"
          :items="timezones"
          v-model="location.timezone"
          class="ml-2 mt-n3"
          placeholder="Timezone"
          :rules="
            this.location.timezone
              ? [rules.required]
              : []
          "
          :error-messages="fieldErrors.timezone || []"
          outlined
          dense
          height="40"
          item-text="name"
          item-value="code"
          @change="changeInput('timezone')"
        >
        <template #label>
              <span class="pl-1 pr-1">{{$t('assets.locations.locationsInfoEdit.timezone')}}</span>
        </template>
        </v-select>
        </v-col>
      </v-row>
      <v-row class="px-1">
        <v-col md="8" class="px-2 py-1">
          <GmapMap
              ref="map"
              @input="changeInput('name')"
              :options="map.options"
              :center="map.center"
              :zoom="map.zoom"
              style="width: 100%; height: 278px; border: solid 1px #dedede; border-radius: 4px;"
          >
            <GmapMarker
              :position="{
              lat: location.latitude
              ? parseFloat(location.latitude)
              : 43.3438,
              lng: location.longitude
              ? parseFloat(location.longitude)
              : 17.8078}"
                :clickable="true"
                :draggable="true"
                @dragend="updateMarker"
            />
          </GmapMap>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="8" class="py-9">
          <v-textarea
              @input="changeInput('description')"
              :maxlength="1000"
              :counter="1000"
              v-model="location.description"
              outlined
              placeholder="Description"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row class="overflow-y-auto mb-12">
        <v-col md="8" class="d-flex align-center justify-space-between">
          <v-autocomplete
              v-model="location.tag_uuids"
              @input="changeInput('tag_uuids')"
              :items="tags"
              placeholder="Enter tags for your new location"
              dense
              outlined
              height="40px"
              append-icon=""
              @update:search-input="onTagChange"
              :counter="20"
              item-text="name"
              item-value="uuid"
              @keydown.enter="onAddTag"
              :search-input.sync="tagToAdd"
              chips
              hint="asd"
              persistent-hint
              maxlength="20"
              deletable-chips
              multiple>
            <template v-slot:message>
              <div class="mt-2 ml-n3 mb-10">
                <span>{{ $t('assets.locations.locationsInfoEdit.tagsSubtitle') }}</span>
              </div>
            </template>
            <template v-slot:label>
              <span class="pl-1 pr-1">{{ $t('assets.locations.locationsInfoEdit.tags') }}</span>
            </template>
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title class="d-flex align-start justify-space-between">
                  <span class="font-weight-regular">{{ data.item.name }}</span>
                  <span class="font-weight-light">{{ data.item['total_number'] || 0 }} devices and locations</span>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:no-data>
              <v-list-item-content>
                <v-list-item-title class="d-flex align-start justify-space-between px-4">
                  <span class="font-weight-regular caption">{{ tagToAdd }}</span>
                  <span class="font-weight-light caption"></span>
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="mb-12">
        
      <!-- did it like so to have more readible code and,
        one source of truth for location data object and error handling-->
  <v-col md="8" class="mb-16">
    <div class="pb-6">
      <span>{{ $t('assets.tabs.custom_fields') }}</span>
    </div>
    <v-row>
      <!-- Custom Field 1 -->
      <v-col
        cols="12"
        class="py-1"
        v-if="location.custom_1_enabled"
      >
        <v-text-field
          v-model="location.custom_1"
          :label="location.custom_1_alias"
          @input="changeInput('custom_1')"
          :rules="[rules.required]"
          :error-messages="fieldErrors.custom_1 || []"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <!-- Custom Field 2 -->
      <v-col
        cols="12"
        class="py-1"
        v-if="location.custom_2_enabled"
      >
        <v-text-field
          v-model="location.custom_2"
          :label="location.custom_2_alias"
          @input="changeInput('custom_2')"
           :rules="[rules.required]"
           :error-messages="fieldErrors.custom_2 || []"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <!-- Custom Field 3 -->
      <v-col
        cols="12"
        class="py-1"
        v-if="location.custom_3_enabled"
      >
        <v-text-field
          v-model="location.custom_3"
          :label="location.custom_3_alias"
          @input="changeInput('custom_3')"
           :rules="[rules.required]"
           :error-messages="fieldErrors.custom_3 || []"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <!-- Custom Field 4 -->
      <v-col
        cols="12"
        class="py-1"
        v-if="location.custom_4_enabled"
      >
        <v-text-field
          v-model="location.custom_4"
          :label="location.custom_4_alias"
          @input="changeInput('custom_4')"
           :rules="[rules.required]"
           :error-messages="fieldErrors.custom_4 || []"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <!-- Custom Field 5 -->
      <v-col
        cols="12"
        class="py-1"
        v-if="location.custom_5_enabled"
      >
        <v-text-field
          v-model="location.custom_5"
          :label="location.custom_5_alias"
          @input="changeInput('custom_5')"
           :rules="[rules.required]"
           :error-messages="fieldErrors.custom_5 || []"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <!-- Custom Field 6 -->
      <v-col
        cols="12"
        class="py-1"
        v-if="location.custom_6_enabled"
      >
        <v-text-field
          v-model="location.custom_6"
          :label="location.custom_6_alias"
          @input="changeInput('custom_6')"
           :rules="[rules.required]"
           :error-messages="fieldErrors.custom_6 || []"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <!-- Custom Field 7 -->
      <v-col
        cols="12"
        class="py-1"
        v-if="location.custom_7_enabled"
      >
        <v-text-field
          v-model="location.custom_7"
          :label="location.custom_7_alias"
          @input="changeInput('custom_7')"
           :rules="[rules.required]"
           :error-messages="fieldErrors.custom_7 || []"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <!-- Custom Field 8 -->
      <v-col
        cols="12"
        class="py-1"
        v-if="location.custom_8_enabled"
      >
        <v-text-field
          v-model="location.custom_8"
          :label="location.custom_8_alias"
          @input="changeInput('custom_8')"
           :rules="[rules.required]"
           :error-messages="fieldErrors.custom_8 || []"
          outlined
          dense
        ></v-text-field>
      </v-col>
    </v-row>
  </v-col>
</v-row>
      <v-row class="text-right bottom-navigation-edit py-4 px-2"
       v-bind:style= "[this.$vuetify.theme.dark ? {'background-color': '#1E1E1E'} : {'background-color':'#ffffff'}]"
      >
        <v-col>
          <v-btn
              width="120"
              class="mr-2 font-weight-regular letter-spacing-normal text-capitalize"
              depressed
              text
              @click="closeForm()"
          >{{ $t("common.cancelBtn") }}
          </v-btn>
          <v-btn
              width="120"
              id="btn-blue-disabled"
              class="font-weight-normal letter-spacing-normal text-capitalize"
              color="primary"
              depressed
              :disabled="!changed"
              @click="editLocation()"
          >{{ $t("common.save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import LocationsApi from "../../services/api/Locations";
import FlyoutMenuHead from "../FlyoutMenuHead";
import {gmapApi} from "vue2-google-maps";
import {mapActions, mapGetters} from "vuex";


export default {
  name: "LocationInfoEdit",
  components: {
    FlyoutMenuHead,
  },
  data: () => ({
    loading: false,
    errorDisplayId: "",
    fieldErrors: {
      name: [],
      display_id: [],
      country_code: [],
      city: [],
      address: [],
      zip_code: [],
      timezone: [],
      description: [],
      tag_uuids: [],
      custom_1: [],
      custom_2: [],
      custom_3: [],
      custom_4: [],
      custom_5: [],
      custom_6: [],
      custom_7: [],
      custom_8: [],
    },
    map: {
      pin: {
        position: {
          lat: 0,
          lng: 0,
        },
      },
      center: {
        lat: 43.3438,
        lng: 17.8078,
      },
      zoom: 7,
      options: {
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        scaleControl: false,
        zoomControl: true,
        styles: [
          {
            featureType: "water",
            stylers: [
              {
                visibility: "on",
              },
              {
                color: "#b5cbe4",
              },
            ],
          },
          {
            featureType: "landscape",
            stylers: [
              {
                color: "#efefef",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [
              {
                color: "#83a5b0",
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "geometry",
            stylers: [
              {
                color: "#bdcdd3",
              },
            ],
          },
          {
            featureType: "road.local",
            elementType: "geometry",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [
              {
                color: "#e3eed3",
              },
            ],
          },
          {
            featureType: "administrative",
            stylers: [
              {
                visibility: "on",
              },
              {
                lightness: 33,
              },
            ],
          },
          {
            featureType: "road",
          },
          {
            featureType: "poi.park",
            elementType: "labels",
            stylers: [
              {
                visibility: "on",
              },
              {
                lightness: 20,
              },
            ],
          },
          {},
          {
            featureType: "road",
            stylers: [
              {
                lightness: 20,
              },
            ],
          },
        ],
      },
    },
    location_rules: {},
    location_types: [],
    countries: [],
    timezones: [],
    location: {},
    enableTimer: false,
    tagToAdd: "",
    timer: null,
    rules: {
      required: (value) => !!value || "Required.",
    },
    changed: false,

  }),
  mounted() {
    this.getLocation(this.$route.params.uuid)
    .then(() => {
      this.getLocationTypes();
      this.getCountries();
      this.getTags();
      this.getTimezones(); // Call this after location is fetched
    });
  },
  watch: {
    getSelectedLocation: {
      handler() {
        this.location = Object.assign({}, this.getSelectedLocation);
        // Ensure global_timezone_enabled is correctly set
        if (typeof this.location.global_timezone_enabled === 'undefined') {
          this.location.global_timezone_enabled = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({"getSelectedLocation": "locations/getSelectedLocation", "tags": "tags/tags", "getDisplayId": "getLocationDisplayId" }),
    google: gmapApi,
  },

  methods: {
    ...mapActions({"getLocation": "locations/getLocationByUUID", "getTags": "tags/getTags", "addTag": "tags/addTag" }),
    
    editLocation() {
      this.loading = true;
      this.fieldErrors = {}; // Clear previous errors

      // Validate custom fields before making API call
      if (!this.validateCustomFields()) {
        this.loading = false;
        return; // Exit if validation fails
      }

      // Check if timezone matches space timezone
      this.location.global_timezone_enabled = this.location.timezone === this.location.space_timezone;

      // Prepare the payload with required custom fields only
      const customData = {};
      for (let i = 1; i <= 8; i++) {
        if (this.location[`custom_${i}_enabled`]) {
          customData[`custom_${i}`] = this.location[`custom_${i}`];
          customData[`custom_${i}_alias`] = this.location[`custom_${i}_alias`];
          customData[`custom_${i}_enabled`] = this.location[`custom_${i}_enabled`];
        }
      }

      const payload = {
        name: this.location.name,
        description: this.location.description,
        display_id: this.location.display_id,
        zip_code: this.location.zip_code,
        country_code: this.location.country_code,
        city: this.location.city,
        address: this.location.address,
        latitude: this.location.latitude,
        longitude: this.location.longitude,
        tag_uuids: this.location.tag_uuids,
        timezone: this.location.timezone,
        global_timezone_enabled: this.location.global_timezone_enabled,
        ...customData,
      };

      // Make the API call to edit the location
      LocationsApi.editLocation(this.location.space_uuid, this.location.uuid, payload)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit("addAlert", {
              type: "success",
              shown: true,
              message: `Location ${this.location.name} updated successfully.`,
            });
            this.$store.commit("locations/UPDATE_LOCATION", response.data);
            this.$store.commit("locations/SET_SELECTED_LOCATION", response.data);
            this.$store.commit("locations/SET_FLYOUT", {
              editLocation: false,
              editDevice: false,
            });
            this.$router.go(-1);
            this.$store.commit("SET_LOCATION_DISPLAY_ID", "");
            this.loading = false;
            this.changed = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response && error.response.status === 400) {
            if (error.response.data && error.response.data.message) {
              this.fieldErrors.general = error.response.data.message;
            } else if (error.response.data.errors) {
              this.fieldErrors = error.response.data.errors;
            }
          } else {
            this.$store.commit("addAlert", {
              type: "error",
              shown: true,
              message: "An unexpected error occurred",
            });
          }
        });
    },
    validateCustomFields() {
    this.fieldErrors = {}; // Reset field errors

    for (let i = 1; i <= 8; i++) {
      const field = `custom_${i}`;
      if (this.location[`${field}_enabled`] && !this.location[field]) {
        this.$set(this.fieldErrors, field, `${field} cannot be blank`); // Set error message for empty fields
      }
    }
    
    return Object.keys(this.fieldErrors).length === 0; // Return true if no errors
  },
    onAddTag() {
      this.addTag(this.tagToAdd).then(response => {
        console.log(response);
      }).catch((error) => {
        console.log(error);
      })
    },
    onTagChange(tag) {
      this.tagToAdd = tag;
    },
    updateSearch() {
      if (this.enableTimer) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.findPlaces();
        }, 400);
      }
    },
    findPlaces() {
      let query = "";
      if (this.location.country_code) {
        query += `${this.location.country_code}`;
      }
      if (this.location.city) {
        query += `+${this.location.city.split(" ").join("+")}`;
      }
      if (this.location.address) {
        query += `+${this.location.address.split(" ").join("+")}`;
      }
      let that = this;
      this.$refs.map.$mapPromise.then((map) => {
        let service = new this.google.maps.places.PlacesService(map);
        service.findPlaceFromQuery(
            {
              query: query,
              fields: ["name", "geometry"],
            },
            function (results) {
              if (results) {
                that.updateMarkerLocation(
                    results[0].geometry.location.lat(),
                    results[0].geometry.location.lng()
                );
              }
            }
        );
      });
    },
    updateMarkerLocation(lat, lng) {
      this.location.latitude = lat;
      this.location.longitude = lng;
    },
    updateMarker(e) {
      this.changed = true;
      this.location.latitude = e.latLng.lat();
      this.location.longitude = e.latLng.lng();
      this.changeInput('latitude');
      this.changeInput('longitude');
    },
    getCountries() {
      LocationsApi.getCountries()
          .then((response) => {
            this.countries = response.data;
          })
          .catch((err) => {
            this.$store.commit("updateAlert", {
              type: "error",
              shown: true,
              message: `Failed to get countries. Check console.`,
            });
            console.log(err);
          });
    },
    closeForm(){
      this.$router.go(-1)
  },
  changeInput(field) {
    // Clear error for the field if it exists in fieldErrors
    if (this.fieldErrors[field]) {
      this.$set(this.fieldErrors, field, []);
    }

    // Handle general change tracking for other fields
    let inputData = this.location;
    let currentData = this.getSelectedLocation;

    // Check if currentData is available
    if (!currentData || Object.keys(currentData).length === 0) {
      return;
    }

    // Check if timezone has changed
    if (inputData.timezone !== currentData.timezone) {
      this.changed = true;
      this.location.global_timezone_enabled = false; // Disable global timezone
    } else {
      // Check for changes in other fields, including custom fields
      if (
        inputData.name !== currentData.name ||
        inputData.description !== currentData.description ||
        inputData.address !== currentData.address ||
        inputData.country_code !== currentData.country_code ||
        inputData.city !== currentData.city ||
        inputData.zip_code !== currentData.zip_code ||
        JSON.stringify(inputData.tag_uuids) !== JSON.stringify(currentData.tag_uuids) ||
        inputData.display_id !== currentData.display_id ||
        // Directly check each custom field
        (inputData.custom_1_enabled && inputData.custom_1 !== currentData.custom_1) ||
        (inputData.custom_2_enabled && inputData.custom_2 !== currentData.custom_2) ||
        (inputData.custom_3_enabled && inputData.custom_3 !== currentData.custom_3) ||
        (inputData.custom_4_enabled && inputData.custom_4 !== currentData.custom_4) ||
        (inputData.custom_5_enabled && inputData.custom_5 !== currentData.custom_5) ||
        (inputData.custom_6_enabled && inputData.custom_6 !== currentData.custom_6) ||
        (inputData.custom_7_enabled && inputData.custom_7 !== currentData.custom_7) ||
        (inputData.custom_8_enabled && inputData.custom_8 !== currentData.custom_8)
      ) {
        this.changed = true;
      } else {
        this.changed = false;
      }
    }
  },

    getLocationTypes() {
      LocationsApi.getTypes()
          .then((response) => {
            this.location_types = response.data;
            this.location_types.forEach((type) => {
              switch (type.name) {
                case "Office Building":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  this.location_rules[type.uuid].address = [this.rules.required];
                  this.location_rules[type.uuid].zip_code = [this.rules.required];
                  break;
                case "Single Office":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  this.location_rules[type.uuid].address = [this.rules.required];
                  this.location_rules[type.uuid].zip_code = [this.rules.required];
                  break;
                case "Shop":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  this.location_rules[type.uuid].address = [this.rules.required];
                  this.location_rules[type.uuid].zip_code = [this.rules.required];
                  break;
                case "Factory":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  this.location_rules[type.uuid].address = [this.rules.required];
                  this.location_rules[type.uuid].zip_code = [this.rules.required];
                  break;
                case "Warehouse":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  this.location_rules[type.uuid].address = [this.rules.required];
                  this.location_rules[type.uuid].zip_code = [this.rules.required];
                  break;
                case "Home":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  break;
                case "Apartment":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  break;
                case "Event Venue":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  this.location_rules[type.uuid].address = [this.rules.required];
                  this.location_rules[type.uuid].zip_code = [this.rules.required];
                  break;
                case "Mall":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  this.location_rules[type.uuid].address = [this.rules.required];
                  this.location_rules[type.uuid].zip_code = [this.rules.required];
                  break;
                case "Land":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  break;
                case "Mobile":
                  this.location_rules[type.uuid] = {};
                  return;
                case "Other":
                  this.location_rules[type.uuid] = {};
                  break;
                default:
                  return;
              }
              this.location = Object.assign({}, this.getSelectedLocation);
              this.location.latitude = parseInt(this.location.latitude, 10);
              this.location.longitude = parseInt(this.location.longitude, 10);

              this.findPlaces();
              this.enableTimer = true;
            });
          })
          .catch((err) => {
            this.$store.commit("updateAlert", {
              type: "error",
              shown: true,
              message: `Failed to get location types. Check console.`,
            });
            console.log(err);
          });
    },
    
    getTimezones() {
    // Retrieve the list of supported time zones
    const timeZoneIdentifiers = Intl.supportedValuesOf('timeZone');
    const getSpaceTimezone = this.location.space_timezone;

    // Map the array of strings to an array of objects with 'name' and 'code' properties
    let tmpTimezones = timeZoneIdentifiers.map((tz) => ({
      name: tz,
      code: tz,
    }));

    // Loop through tmpTimezones and check if name matches getSpaceTimezone
    tmpTimezones = tmpTimezones.map(tz => {
      if (tz.name === getSpaceTimezone) {
        return {
          ...tz,
          name: this.$t('assets.locations.locationsGeneral.globalTimezone')
        };
      }
      return tz;
    });

    // Filter out the space timezone from the list and move it to the top
    const spaceTimezone = tmpTimezones.find(tz => tz.name === this.$t('assets.locations.locationsGeneral.globalTimezone'));
    tmpTimezones = tmpTimezones.filter(tz => tz.name !== this.$t('assets.locations.locationsGeneral.globalTimezone'));

    // Set the space timezone on top and update the timezones list
    this.timezones = [spaceTimezone, ...tmpTimezones];
  },

  },

};
</script>

<style scoped lang="scss">
.add-device-btn {
  width: 120px;
}

.bottom-navigation-edit {
  position: absolute;
  bottom: 0 !important;
  left: 0;
  right: 0;
  margin: 0;
  box-shadow: 0 -4px 25px 0 rgba(0, 0, 0, 0.13);
 
}

::v-deep {
  .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: var(--v-babyBlueish-base) !important;
    color: white !important;
  }
}
</style>
