<template>
  <div>
    <v-row>
      <v-tabs style="border-bottom: 1px solid var(--v-borderColor-base);">
       <!--
        this will be needed in the future 
        <v-tab active-class="selected-tab" class="px-6" @click="selectTab('working_time')">
          {{ $t("assets.tabs.devicesTab") }}
        </v-tab>
       --> 
        <v-tab active-class="selected-tab" class="px-6" @click="selectTab('custom_fields')">
          {{ $t("assets.tabs.locationsTab") }}
        </v-tab>
      </v-tabs>
    </v-row>
    <div class="container">
      <div class="scrollable-content">
        <v-row class="justify-center">
          <v-col cols="12" class="mb-0 pb-0 pt-1">
            <custom-field-item
              v-for="(customField, index) in getCustomFields"
              :key="index"
              :field="customField"
              @update-field="updateField"
            />
          </v-col>
        </v-row>
      </div>
      <v-row class="button-row justify-end ml-4">
        <v-col cols="auto">
          <v-btn large class="text-capitalize mr-4" color="secondary">{{ $t('assets.working_time.button.cancel') }}</v-btn>
          <v-btn large class="text-capitalize" color="primary" @click="handleSave">{{ $t('assets.working_time.button.save') }}</v-btn>
        </v-col>
      </v-row>
    </div>
    <Alert class="bottom-alert"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Alert from '../components/Alert';
import CustomFieldItem from '../components/CustomFields/CustomFieldItem.vue';
import Locations from "../services/api/Locations";

export default {
  components: {
    CustomFieldItem,
    Alert
  },
  mounted() {
    this.initializeCustomFields();
  },
  methods: {
    ...mapActions({
      addAlert: "global/addAlert",
      customFields: "locations/getSpaceCustomFields"
    }),
    initializeCustomFields() {
      this.customFields(); // Fetch the initial fields
    },

    updateField(index, updatedField) {
      let data = [...this.getCustomFields];
      const fieldIndex = data.findIndex(field => field.index === index);

      if (fieldIndex !== -1) {
        this.$set(data, fieldIndex, updatedField);
      } else {
        console.warn(`Field with index ${index} not found.`);
      }

      // Save the updated fields for use when the save button is clicked
      this.updatedCustomFields = data; // Storing updated fields
    },
    handleSave() {
      if (this.updatedCustomFields) {
        this.saveCustomFields(this.updatedCustomFields);
      } else {
        console.warn('No changes made to custom fields.');
      }
    },

    saveCustomFields(fields) {
  const payload = fields.reduce((acc, field) => {
    acc[`custom_${field.index}_alias`] = field.alias;
    acc[`custom_${field.index}_enabled`] = field.enabled;
    acc[`custom_${field.index}_required`] = field.required;
    acc[`custom_${field.index}_unique`] = field.unique;
    return acc;
  }, {});

  Locations.editSpace(null, payload) // Ensure to pass the correct parameters here
    .then(response => {

      // Check if response has data and handle it
      if (response && response.data) {
        this.addAlert({ message: 'Custom fields updated successfully!', type: 'success' });
      } else {
        console.error('Unexpected response structure:', response);
        this.addAlert({ message: 'Failed to update custom fields: Unexpected response structure.', type: 'error' });
      }
    })
    .catch(error => {
      // Improved error handling
      console.error('Failed to update custom fields:', error);

      // Check if the error response has a message and log it
      if (error.response) {
        console.error('Error details:', error.response.data);
        this.addAlert({ message: `Failed to update custom fields: ${error.response.data.message || 'Unknown error'}`, type: 'error' });
      } else {
        this.addAlert({ message: 'Failed to update custom fields: Unknown error.', type: 'error' });
      }
    });
  }

  },
  computed: {
    ...mapGetters({
      getCustomFields: "locations/getCustomFields"
    }),
    updatedCustomFields: {
      // Create a reactive property to store updated fields
      get() {
        return this._updatedCustomFields || [];
      },
      set(value) {
        this._updatedCustomFields = value;
      }
    }
  }
}
</script>

<style scoped>
.container {
  width: 60%;
  display: flex;
  flex-direction: column;
}
.scrollable-content {
  max-height: calc(82vh - 120px); /* or whatever maximum height you want */
  overflow-y: auto;  /* make it scrollable when content overflows */
}
.top-text {
  width: 100%;
}
.top-text h3, .top-text span {
  margin: 0;
}

.button-row {
  width: 100%;
  justify-content: flex-end;
}
.bottom-alert {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}
@media screen and (max-width: 600px){
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
}
</style>
