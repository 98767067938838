const messages = {
  en: {
    sidebar: {
      assetsView: "Assets",
      accountsView: "Accounts",
      monitorView: "Monitor",
      applicationsView: "Admin Panel"
    },
    common: {
      cancelBtn: "Cancel",
      sendBtn: "Send",
      unknown: "Unknown",
      save: "Save",
      new: "New",
      close: "Close"
    },
    control_panel:{
      title: "Control Panel"
    },
    monitor:{
      title: "Monitor",
      tabs:{
        activityTab: "Activity",
        performanceTab:  "Performance",
        logsTab: "Audit Logs",
        eventTab: "System Events"
      }
    },
    application:{
      title: "Application",
      tabs:{
        appliactionTab: "Applications",
        runnersTab: "Runners"
      }
    },
    assets: {
      title: "Assets",
      tabs: {
        locationsTab: "Locations",
        devicesTab: "Devices",
        groupsTab: "Location Groups",
        working_time: "Working Time",
        custom_fields: "Custom Fields"
      },
      devices: {
        emptyDevicesTitle: "It appears there are no added Devices",
        emptyDevicesDescription: "To add one, hover the Plus button at the bottom right of your screen and choose appropriate option.",
        emptySearchTitle: "No results found",
        emptySearchDescription: "Try a different search query",
        searchPlaceholder: "Search",
        fab: {
          addRunner: "Add Runner Device",
          addManually: "Add Device Manually",
          detect: "Detect Devices"
        },
        addRunner: {
          title: "Add Runner Device",
          description: "Once added, you will be able to connect and manage runner device.",
          addNew: "Add New",
          deviceNameLabel: "Device Name",
          deviceNamePlaceholder: "Friendly name for your device",
          deviceDescriptionLabel: "Description",
          deviceLocationLabel: "Location",
          deviceLocationPlaceholder: "Allocate device to Location",
          addBtn: "Add"
        },
        deviceInfo: {
          tabs: {
            general: "General",
            peripherals: "Peripherals",
            assetInfo: "Asset Info",
            settings: "Settings",
            devices: "Devices",
            logs: "Logs",
            reports: "Reports",
            working_time: "Working time"
          },
          installationCode: {
            codeMessage: "New device has been added. Use the following code to connect via Chameleon Runner within the next 24 hours:",
            codeExpiredMessage: "Code has expired. If you still wish to connect to this device, generate new code by clicking \"Reset Code\" button, otherwise you may delete it to keep things tidy.",
            codeResetMessage: "Code has been reset. Old code won't work anymore. Use the following code to connect via Chameleon Runner within the next 24 hours:",
            codeEmailSentMessage: "Email with code sent to ",
            codeEmailSentInfo: "Once the recipient establishes connection with added device, you will be able to manage it. If you need to, you can always make sent code invalid by resetting it.",
            expiresIn: "Expires in: ",
            resetCodeBtn: "Reset Code",
            codeInfoWhenUsed: "Once the code is successfully used, added device will become connected. In the meantime, you can always reset code if you need to.",
            codeInfoSendEmail: "You can also send this code to desired email address. Recipient will receive code, custom message and default instructions on how to use code to connect."
          },
          info: {
            deviceCategoryLabel: "Device Category",
            deviceTypeLabel: "Device Type",
            connectionStatusLabel: "Connection Status",
            deviceStatusLabel: "Device Status",
            onlineStreakLabel: "Online Streak",
            offlineStreakLabel: "Offline Streak",
            locationLabel: "Location",
            addedOnLabel: "Added On",
            uuidLabel: "UUID",
            machineID: "Machine ID",
            actionsLabel: "Actions",
            connectionStatusOnline: "Online",
            connectionStatusOffline: "Offline",
            deviceStatusEnabled: "Enabled",
            deviceStatusDisabled: "Disabled",
            ipAddressLabel: "IP Address",
          },
          typeDescriptions: {
            PC: "Connected computer can be used for a variety of purposes, such as displaying apps in kiosk mode, or running background services and providing processing power for services demanding apps.",
            'Linux Computer': "Connected computer can be used for a variety of purposes, such as displaying apps in kiosk mode, or running background services and providing processing power for services demanding apps.",
            'RaspberryPi': "Connected RaspberryPi can be used for a variety of purposes, such as displaying apps in kiosk mode, or running background services and providing processing power for services demanding apps."
          }
        },
      },
      locations:{
        locationsGeneral: {
          type: 'Location Type',
          country: 'Country',
          city: 'City',
          address: 'Address',
          zipCode: 'Zip Code',
          geolocation: 'Geolocation',
          addedOn: 'Added On',
          uuid: 'UUID',
          onlineDevices: 'Has Online Devices',
          onlineAccounts: 'Has Online Accounts',
          locationDisplayId: 'Location Display Id',
          locationTimezone: 'Location Timezone',
          globalTimezone: 'UTC+1 (Space Timezone)'
        
        },
        locationsInfoEdit: {
          title: 'General',
          locationName: 'Location Name',
          timezone: 'Timezone',
          tags: 'Tags',
          tagsSubtitle: 'To add a new hint, type it and press ENTER.'

        }
      },
      working_time: {
        headline: 'Working time',
        global: "Set global time for all locations",
        local: "Working time defined by global or local settings",
        button: {
          save: 'Save Changes',
          cancel: 'Discard',
          global: 'Global',
          local: 'Local'
        }
      },
      custom_fields: {
        headline: 'Working time',
        global: "Set global time for all locations",
        local: "Working time defined by global or local settings",
        button: {
          save: 'Save Changes',
          cancel: 'Discard',
          global: 'Global',
          local: 'Local',
        },
        radio: {
          required: 'Required',
          notRequired: 'None',
          requiredUnique: 'Required & Unique'
        },
        item: {
          notRequired: 'Not Required',
          required: 'Required',
          requiredUnique: 'Required & Unique'
        }
      },
      groups: {
        groupInfo:{
          tabs: {
            location: "Location",
          },
        },
        info: {
          created_at_label: "Created At",
          uuid_label: "UUID",
          number_of_group_locations: "Number Of Associated Locations"
        }
      }
    }
  },

  hr: {
    sidebar: {
      assetsView: "Prostor",
      accountsView: "Korisnici"
    },
    assets: {
      title: "Prostor",
      tabs: {
        locationsTab: "Lokacije",
        devicesTab: "Uređaji"
      },
      devices: {
        searchPlaceholder: "Pretraga",
        emptyDevicesTitle: "Izgleda da nema dodanih uređaja",
        emptyDevicesDescription: "Da biste dodali uređaj, pređite mišem preko plusa na donjoj desnoj strani vašeg ekrana i odaberite željenu opciju.",
        emptySearchTitle: "Nema pronađenih uređaja",
        emptySearchDescription: "",
        fab: {
          addRunner: "Dodaj Runner uređaj",
          addManually: "Dodaj uređaj ručno",
          detect: "Detektiraj uređaje"
        },
        addRunner: {
          title: "Dodaj runner uređaj",
          description: "Nakon dodavanja uređaja, isti ćete moći povezati i upravljati njime.",
          addNew: "Dodaj novi",
          deviceNameLabel: "Naziv uređaja",
          deviceNamePlaceholder: "Naziv uređaja koji će se prikazivati",
          deviceDescriptionLabel: "Opis",
          deviceLocationLabel: "Lokacija",
          deviceLocationPlaceholder: "Pridružite Vaš uređaj nekoj lokaciji",
          addBtn: "Dodaj"
        },
        deviceInfo: {
          tabs: {
            general: "Generalno",
            peripherals: "Periferija",
            assetInfo: "Informacije",
            settings: "Postavke",
            devices: "Uređaji"
          },
          installationCode: {
            codeMessage: "Novi uređaj je dodan. Koristite sljedeći instalacijski kod da ga spojite preko Chameleon Runner-a:",
            codeExpiredMessage: "Instalacijski kod je istekao. Ako još uvijek želite spojiti ovaj uređaj, generirajte novi instalacijski kod kliknom na \"Resetiraj Kod\" dugme, u suprotnom ga možete obrisati da držite prostor čistim.",
            codeResetMessage: "Instalacijski kod je resetiran. Stari instalacijski kod neće više raditi. Koristite sljedeći instalacijski kod u naredna 24 sata da spojite uređaj preko Chameleon Runner-a:",
            codeEmailSentMessage: "Instalacijski kod je poslan na email ",
            codeEmailSentInfo: "Kada primatelj instalira uređaj preko instalacijskog koda, moći ćete upravljati uređajem. Ako trebate, uvijek možete poslani kod učiniti neispravnim tako što ćete ga resetirati.",
            expiresIn: "Ističe za: ",
            resetCodeBtn: "Resetiraj kod",
            codeInfoWhenUsed: "Nakon što je instalacijski kod uspješno iskorišten, uređaj će postati povezan. U međuvremenu, uvijek možete resetirati instalacijski kod ako je potrebno.",
            codeInfoSendEmail: "Ovaj instalacijski kod možete poslati na email. Primatelj će dobiti instalacijski kod, prilagođenu poruku i zadani set instrukcija kako iskoristiti instalacijski kod."
          },
          info: {
            deviceCategoryLabel: "Kategorija uređaja",
            deviceTypeLabel: "Tip uređaja",
            connectionStatusLabel: "Status veze",
            deviceStatusLabel: "Status uređaja",
            onlineStreakLabel: "Vrijeme rada",
            locationLabel: "Lokacija",
            addedOnLabel: "Datum dodavanja",
            uuidLabel: "Unikatni identifikacijski ID (UUID)",
            actionsLabel: "Akcije",
            connectionStatusOnline: "Spojen",
            connectionStatusOffline: "Nije spojen",
            deviceStatusEnabled: "Omogućen",
            deviceStatusDisabled: "Onemogućen",
          },
          typeDescriptions: {
            PC: "Spojeni računar se može koristiti u više svrha, kao što je prikaz aplikacija u kiosk načinu, ili pokretanju pozadinskih aplikacija i pružanju procesorske snage za potrebne aplikacije."
          }
        }
      }
    }
  }
};
export default messages